import {
  addBins,
  deleteBins,
  getBins,
  getBinsByID,
  updateBins,
  searchBins,
} from "../API/bins.api";

import axiosCall from "../API/axios";
import store from "src/store";

const getAllBins = async() => {
  const mrfAdmin = JSON.parse(localStorage.getItem("mrfAdmin"))
  let mrf_admin;
  if(mrfAdmin.type==='employee'){
    mrf_admin = mrfAdmin.user.mrf_admin
  }else{
    mrf_admin = mrfAdmin.user.id
  }
  
  const res = axiosCall(`${getBins}?mrf_admin=${mrf_admin}`, "", "GET");

  return res;
};

const Addbins = (data) => {
  return axiosCall(addBins, data, "POST");
};

const Updatebins = (data) => {
  return axiosCall(updateBins, data, "POST");
};

const binsGetById = (data) => {
  let url = `${getBinsByID}/${data}`;
  return axiosCall(url, "", "GET");
};

const searchBin = async (seach) => {
  const mrfAdmin = await JSON.parse(localStorage.getItem("mrfAdmin"))

  let mrf_admin;
  if(mrfAdmin.type==='employee'){
    mrf_admin = mrfAdmin.user.mrf_admin
  }else{
    mrf_admin = mrfAdmin.user.id
  }

  return axiosCall(`${searchBins}?${seach}&mrf_admin=${mrf_admin}`, "", "GET");
};
export default {
  getAllBins,
  Addbins,
  Updatebins,
  binsGetById,
  searchBin,
};
