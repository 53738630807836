import { GetAllUser } from "src/API/user.api";
import { SearchUser } from "../API/user.api";
import axiosCall from "../API/axios";


const getAllUser = () => {
  return axiosCall(GetAllUser)
}

const searchUser = (seach) => {
  return axiosCall(`${SearchUser}?${seach}`, "", "GET");
};

export default { getAllUser, searchUser };
