import axios from "axios";
const apiCall = async (url, data, method, params={}) => {
  try {
    let token = JSON.parse(localStorage.getItem("mrfAdmin"));
    var config;
    if (token) {
      config = {
        method,
        url,
        params,
        headers: {
          Authorization: `Bearer ${token.token}`,
          "Content-Type": "application/json",
        },
        data: data,
      };
    } else {
      config = {
        method,
        url,
        params,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
    }   
    return await axios(config);
  } catch (err) {
  }
};

export default apiCall;
