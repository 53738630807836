import {
  getCategory,
  addCategory,
  updateCategory,
  getCategoryByID,
  getAllCategoryByAdmin,
  addCategoryMrf,
  updateCategoryMrf,
} from "../API/category";
import axiosCall from "../API/axios";
import store from "src/store";

const getAllCategory = async (offset, limit) => {
  const storedData = await JSON.parse(localStorage.getItem("mrfAdmin"));

  let mrf_admin;
  if (storedData.type === "employee") {
    mrf_admin = await storedData.user.mrf_admin;
  } else {
    mrf_admin = await storedData.user.id;
  }

  const res = await axiosCall(
    `${getCategory}?offset=${offset}&limit=${limit}`,
    "",
    "GET",
    {
      mrfAdmin: mrf_admin,
    }
  );
  if (res) {
    store.dispatch({
      type: "FETCH_CATEGORY_SUCCESS",
      payload: res.data,
    });
  }
  return res;
};

const AddCategory = (data) => {
  return axiosCall(addCategoryMrf, data, "POST");
};

const UpdateCategoryMrf = (data) => {
  return axiosCall(updateCategoryMrf, data, "POST");
};

const categoryGetById = (data) => {
  let url = `${getCategoryByID}/${data}`;
  return axiosCall(url, "", "GET");
};

const gelAllCatByAdmin = () => {
  let url = `${getAllCategoryByAdmin}?limit=1000`;
  return axiosCall(url, "", "GET");
};

export default {
  getAllCategory,
  AddCategory,
  categoryGetById,
  gelAllCatByAdmin,
  UpdateCategoryMrf,
};
