const initialState = {
    data: [],
  };
  
  const getCommunityData = (state = initialState, action) => {
    switch (action.type) {
      case "FETCH_COMMUNITY_SUCCESS":
        return {
          ...state,
          data: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default getCommunityData;
  