import { getitem, addItem, updateItem, getItemById } from "../API/item";
import axiosCall from "../API/axios";

const getAllItem = async () => {
  const storedData = await JSON.parse(localStorage.getItem("mrfAdmin"));

  let mrf_admin = "";
  if (storedData.type === "admin") {
    mrf_admin = storedData.user.id;
  } else {
    mrf_admin = storedData.user.mrf_admin;
  }

  return axiosCall(getitem, "", "GET", { mrf_admin });
};

const getAllItemPagination = async (offset, limit, account_status) => {
  const storedData = await JSON.parse(localStorage.getItem("mrfAdmin"));

  let mrf_admin = "";
  if (storedData.type === "admin") {
    mrf_admin = storedData.user.id;
  } else {
    mrf_admin = storedData.user.mrf_admin;
  }

  if (account_status) {
    return axiosCall(
      `${getitem}?offset=${offset}&limit=${limit}&account_status=arrived`,
      "",
      "GET",
      {
        mrf_admin,
      }
    );
  } else {
    return axiosCall(`${getitem}?offset=${offset}&limit=${limit}`, "", "GET", {
      mrf_admin,
    });
  }
};

const addItems = (data) => {
  return axiosCall(addItem, data, "POST");
};

const updateItems = (data) => {
  return axiosCall(updateItem, data, "POST");
};

const getCategoryItemById = (id) => {
  let url = `${getItemById}/${id}`;
  return axiosCall(url, "", "GET");
};

const searchItem = (data) => {
  let url = `${getitem}?${data}`;
  return axiosCall(url, "", "GET");
};
export default {
  updateItems,
  getCategoryItemById,
  addItems,
  getAllItem,
  getAllItemPagination,
  searchItem,
};
